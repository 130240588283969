@import '_reset';
@import '_utility';
@import '_font';
@import '_cookie';
@import '_footer';


body {
	font-family: $zillaslab;
	font-size: 16pt;
	font-weight: 400;
	line-height: 1.4;
	color: $blu;
	overflow-x: hidden;
}

a {
	color: inherit;
}

b, strong { font-weight: 700; }
i { font-style: italic; }

h2 {
    text-transform: uppercase;
    font-size: 1.1rem;
}

h3 {
    font-weight: bold;
    font-size: 1rem;
}

ul {
    margin-bottom: 20px;

    li {
        list-style: disc;
        margin-left: 17px;
        margin-bottom: 10px;

		@include media('>=tablet') {
			margin-left: calc(17px + 20px);
		}
    }
}

p + p {
    margin-top: 20px;
}

input:focus, select:focus, textarea:focus, button:focus {
    outline: none;
}

.freeze {
    overflow-y: hidden;
}

.keep {
	white-space: nowrap;
}

.highlight {
    display: inline;
    word-wrap: break-word;
}

.highlight--shadow {
    position: relative;
    left: 5px;
    padding-left: 0;
}

.shadow--azzurro {
	box-shadow: 5px 0 0 $azzurro, -5px 0 0 $azzurro;
}

.shadow--violetto {
	box-shadow: 5px 0 0 $violetto, -5px 0 0 $violetto;
}

.nomargin {
	margin: 0 !important;
}

.phone {
	display: block;
	@include media('>=tablet') {
		display: none;
	}
}

.mobile {
	display: block;
	@include media('>=desktop') {
		display: none;
	}
}

.desktop {
	display: none;
	@include media('>=desktop') {
		display: block;
	}
}

.img--block {
	display: inline-block;
	width: auto;
	max-width: 100%;
}

.spacer20 {height: 10px;@include media('>tablet') {height: 20px;}}
.spacer30 {height: 15px;@include media('>tablet') {height: 30px;}}
.spacer40 {height: 20px;@include media('>tablet') {height: 40px;}}
.spacer60 {height: 30px;@include media('>tablet') {height: 60px;}}

.center {
	text-align: center;
}

.main--wrap {
	overflow-x: hidden;
}

.bianco 	{color: #fff;}
.blu 		{color: $blu}
.azzurro 	{color: $azzurro}
.celeste 	{color: $celeste}
.violetto 	{color: $violetto}

.bg--bianco 	{background: #fff;}
.bg--blu 		{background: $blu}
.bg--azzurro 	{background: $azzurro}
.bg--celeste 	{background: $celeste}
.bg--violetto 	{background: $violetto}

.row {
	position: relative;
	padding: 40px 0;
}

.row--img {
	margin: -100px auto 40px auto;
	text-align: center;
	max-width: 350px;

	img {
		width: 100%;
	}
}

.sbalzo {
	position: relative;
	padding: 40px 0 140px 0;
}

.layout {
    @include gabbia;
}

.layoutStretto {
    @include gabbiaStretta;
}

.outGabbia {
	padding-left: 20px;
	padding-right: 20px;
}

.box {
	display: flex;
	flex-wrap: wrap;
}

.side {
	width: 100%;
	margin-bottom: 20px;

	@include media('>=desktop') {
		width: 50%;
		margin-bottom: 0;
		&:nth-child(odd) { padding-right: 20px; }
		&:nth-child(even) { padding-left: 20px; }
	}
}

.title {
	font-size: 35pt;
	font-style: italic;
	font-weight: bold;
	font-family: $zillaslab;
	text-align: center;
	line-height: 1;
}

.upper {
	font-size: 25pt;
	font-family: $montserrat;
	text-transform: uppercase;

	@include media('>=tablet') {
		font-size: 35pt;
	}

	@include media('>=desktophd') {
		font-size: 65pt;
	}
}

.lower {
	font-size: 30pt;

	@include media('>=tablet') {
		font-size: 40pt;
	}

	@include media('>=desktophd') {
		font-size: 70pt;
	}
}

.title--corsivo {
	font-size: 35pt;
	font-style: italic;
	font-family: $zillaslab;
	text-align: center;
	line-height: 1;
}

.subtitle {
	font-size: 16pt;
	font-weight: 800;
	text-transform: uppercase;
	font-family: $montserrat;
	letter-spacing: 1pt;

	@include media('>=desktophd') {
		font-size: 20pt;
		letter-spacing: 2pt;
	}
}



.abstract {
	font-size: 20pt;
}

.txt {
	font-family: $zillaslab;
	font-size: 13pt;

	p {
		margin-bottom: 20px;
	}

	@include media('>=desktophd') {
		font-size: 16pt;
	}
}

.caption {
	font-size: 13pt;
}

.pulsante--wrap {
	position: relative;
    text-align: center;
	padding: 0 20px;

	@include media('>=tablet') {
		padding: 0;
	}
}

.pulsante {
	display: inline-block;
    font-size: 16px;
	font-weight: bold;
	text-decoration: none;
	color: #fff;
	background: $violetto;
	border-radius: 50px;
	padding: 16px 32px;
	max-width: 400px;
	box-shadow: 0px 6px 0px 0px rgba($blu, 0.2);
	transition: all 200ms ease-in-out;

    &:hover {
        background: darken($violetto, 10%);
		box-shadow: 0px 2px 0px 0px rgba($blu, 0.2);
    }

	@include media('>=tablet') {
		font-size: 21px;
	}
}


.progetto--wrap {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.progetto--logo {
	width: 100%;
	padding: 20px;
	text-align: center;

	@include media('>=desktop') {
		width: 40%;
	}

	img {
		width: 100%;
		max-width: 300px;
		height: auto;
	}
}

.progetto--info {
	width: 100%;
	padding: 20px;

	@include media('>=desktop') {
		width: 60%;
	}
}



.form--group {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	width: 100%;
	max-width: 600px;
	margin: 0 auto 40px auto;
}

.form--label {
	width: 100%;
	font-size: 13pt;
    font-weight: bold;
    font-style: italic;
	text-align: center;
	margin-bottom: 10px;

	@include media('>=tablet') {
		width: 150px;
		margin-bottom: 0;
		margin-right: 10px;
	}
}

.form--field {
	border: 3px solid $blu;
	background: lighten($celeste,10%);
	box-sizing: border-box;
	width: 100%;
	padding: 5px 10px;
	font-size: 20pt;
	font-family: $zillaslab;
	color: $blu;

	@include media('>=tablet') {
		width: calc(100% - 150px - 10px);
	}
}

.form--send {
	text-align: center;
}

.form--send__button {
	font-family: $montserrat;
	font-weight: bold;
	font-size: 20pt;
	color: #fff;
	text-transform: uppercase;
	letter-spacing: 2pt;
	padding: 16px 20px 10px 20px;
	background: $blu;
	border-top: 0;
	border-left: 0;
	border-right: 0;
	border-bottom: 6px solid darken($blu, 10%);
	border-radius: 10px;
	cursor: pointer;

	&:hover {
		background: darken($blu, 10%);
	}
}
