.cookie--wrap {
    background: #69c7e9;
    align-items: stretch;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) translateY(100%);
    transition: transform 150ms ease-out;
    color: #232b5a;
    font-size: 13pt;
    line-height: 1.4;
    z-index: 999999;
    box-shadow: 0 -1px 1px rgba(0,0,0,0.12),
              0 -2px 2px rgba(0,0,0,0.12),
              0 -4px 4px rgba(0,0,0,0.12),
              0 -8px 8px rgba(0,0,0,0.12),
              0 -16px 16px rgba(0,0,0,0.12);
    a {
        color: inherit;
    }

    //m
    width: 100%;
    @include media('>=tablet')
    {
        width: 100%;
        max-width: 900px;
        display: flex;
        justify-content: space-between;
    }
}

.cookie--wrap__on {
    transform: translateX(-50%);
}

.cookie--body {
    padding: 20px;
}

.cookie--chiudi {
    background: #8ea6d6;
    color: #232b5a;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    cursor: pointer;
    &:hover {
        background: darken(#8ea6d6,10%);
    }
}

.cookie--btn {
    text-transform: uppercase;
    font-weight: bold;
}
